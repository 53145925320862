export default {

    created: function () {
        //console.log("Hello from General");
    },

    methods: {

        getCountries() {
            const self = this;

            return new Promise(function(resolve, reject) {
                axios
                    .get("/api/countries", {
                        headers: {
                            Authorization:
                                "Bearer " + self.$root.access_token(),
                        },
                        params: {
                            paginate: false,
                        },
                    })
                    .then((response) => {
                        store.set('countryList', response.data);
                        resolve();
                    })
                    .catch((error) => {
                        console.log("🚀 ~ file: general.js:28 ~ returnnewPromise ~ error:", error)
                    });
            });
        },

        getLanguages() {
            const self = this;
        	
            return new Promise(function(resolve, reject) {
                axios
                    .get("/api/languages", {
                        headers: {
                            Authorization:
                                "Bearer " + self.$root.access_token(),
                        },
                        params: {
                            paginate: false,
                        },
                    })
                    .then((response) => {
                        store.set('languageList', response.data);
                        resolve();
                    })
                    .catch((error) => {
                        console.log("🚀 ~ file: general.js:52 ~ returnnewPromise ~ error:", error)
                    });
            });
        },

        getInitialResources() {
            const self = this;
            return new Promise(function(resolve, reject) {
                Promise.all([
                    self.getCountries(),
                    self.getLanguages(),
                ])
                .then(values => {
                  resolve(values);
                })
                .catch(error => {
                  console.error(error);
                  reject(error);
                });
            });
        },

        toast(msg) {
            this.$buefy.toast.open(msg)
        },

        success(msg) {
            this.$buefy.toast.open({
                message: msg,
                type: 'is-success'
            })
        },

        danger(msg) {
            this.$buefy.toast.open({
                duration: 5000,
                message: msg,
                type: 'is-danger',
                queue: false,
            })
        },

        can(what) {
            const baseAdminAllowed = ['seePastors', 'seeEvents', 'seeMedias', 'seeBanners', 'seePosts', 'seeNotifications', 'seePrayers', 'deletePost'];
            const testimonyAdminAllowed = ['seeTestimonies'];

            if(this.$root.store.user.master===1) {
                // é superadmin
                return true;

            } else {
                if(this.$root.store.user.admin===1) {
                    if(this.$root.store.user.admin_testimony===1) {
                        // é admin de testemunhos
                        if(testimonyAdminAllowed.includes(what)) {
                            return true;
                        } else {
                            return false;
                        }

                    } else {
                        // é admin base tradutores
                        if(baseAdminAllowed.includes(what)) {
                            return true;
                        } else {
                            return false;
                        }
                    }

                } else {
                    //não é admin
                    return false;
                }
            }
        },

        getRecaptchaV3Token(actionName = null) {
            return new Promise((resolve, reject) => {
                grecaptcha.ready(() => {
                    grecaptcha
                    .execute(window.recaptcha_sitekey_v3, { action: actionName })
                    .then((token) => {
                        if (!['', false, null, undefined, [], {}].includes(token)) {
                            resolve(token)
                        } else {
                            resolve(false)
                        }
                    })
                    .catch((error) => {
                        console.log("🚀 ~ file: general.js:115 ~ grecaptcha.ready ~ error:", error)
                        reject(error);
                    });
                });
            });
        },

        getRecaptchaV2Token(elementID) {
            return new Promise((resolve, reject) => {
                grecaptcha.ready(() => {
                    grecaptcha.render(elementID, {
                        'sitekey': window.recaptcha_sitekey_v2,
                        'tabindex': 0,
                        'callback': (token) => {
                            if (!['', false, null, undefined, [], {}].includes(token)) {
                                resolve(token)
                            } else {
                                resolve(false)
                            }
                        },
                        'expired-callback': (expired) => {
                            console.log("🚀 ~ file: general.js:133 ~ grecaptcha.ready ~ expired:", expired)
                        },
                        'error-callback': (error) => {
                            console.log("🚀 ~ file: general.js:135 ~ grecaptcha.ready ~ error:", error)
                        },
                    });
                });
            });
        },

        isMobile() {
            let result;
            if (window.innerWidth <= 768) {
                result = true;

            } else {
                result = false;
            }

            return result;
        }

    },

    filters: {

        limitText(text, max){
            return (text ? text.slice(0, max) + (text.length > max ? "..." : "") : '');
        },

        fromNow: function (date, ignoreTZ=false) {
            if(date) {
                return getMomentDate(date, ignoreTZ).fromNow();
            } else {
                return '';
            }
        },

        calendar: function (date, ignoreTZ=false) {
            if(date) {
                return getMomentDate(date, ignoreTZ).calendar();
            } else {
                return '';
            }
        },

        toBrl(value){
            if(value) {
                let val =  parseFloat(value).toFixed(2).toString().replace('.', ',');
                var newVal = ','+val.split(",")[1];
                var charCount=-1;
                for(let char of val.split(",")[0].split("").reverse().join("")){
                    if(charCount ===2){
                        newVal = '.'+newVal;
                        charCount=0;
                    }else{
                        charCount++;
                    }

                    newVal = char+newVal;
                }
                return newVal;

            }
            return "0,00";
        },

    },
    
}

let getMomentDate = (date, ignoreTZ=false)=>{
    date = moment.tz(date, 'UTC');
    if(!ignoreTZ)
        date.tz('America/Sao_Paulo');
    return date
};