
import { isNavigationFailure, NavigationFailureType } from 'vue-router';

export default {

    created: function () {
        //console.log("Hello from Auth");
    },

    methods: {

        loginFromLocalStorage() {
            var self = this;

            return new Promise(function(resolve, reject) {

		        var auth = JSON.parse(localStorage.getItem("store.state.auth"));

                var auth_since = moment().diff(JSON.parse(localStorage.getItem("store.state.auth_date")));
                const diffDays = Math.ceil(auth_since / (1000 * 60 * 60 * 24)); 

                if(diffDays > 2) {
                    localStorage.setItem("store.state.auth", JSON.stringify({}));     
                    localStorage.removeItem("store.state.auth_date");   
                    reject("LocalStorageToken invalid")
                }

		        if(auth && auth.token_type == 'Bearer') {
			        store.set('auth', auth);
                    
                    resolve();
		        } else {
		        	reject("LocalStorageToken invalid")
		        }
            });
        },

        oauthToken(...args) {
            var self = this;

            return new Promise(function(resolve, reject) {
                axios
                    .post("/oauth/token", {
                        grant_type: "password",
                        client_id: 2,
                        client_secret:
                            "tPtDTLslhBi4B4IA1HNzX9XVHlIOqHjVc96AcXOM",
                        scope: "application",
                        username: args[0],
                        password: args[1]
                    }, 
                    {
                        headers: {
                        }
                    })
                    .then((response) => {

                        if (response.data) {

                        	if(response.data.error) {
                        		reject(response.data.message)
                        	}

                            response.data.timestamp = new Date().getTime();
                            store.set('auth', response.data);
                            localStorage.setItem("store.state.auth", JSON.stringify(response.data));
                            localStorage.setItem("store.state.auth_date", JSON.stringify(new Date().toUTCString()));
                            setTimeout(function (){
                                resolve();
                            }, 2000);
                        }
                    })
                    .catch((error) => {
                        alert("Wrong email or password");
                        Bugsnag.notify(error);
                        reject(error.response.data);
                    });
            });
        },

        getClientToken(...args) {
            var self = this;

            return new Promise(function(resolve, reject) {
                axios
                    .post("/oauth/token", {
                        grant_type: "client_credentials",
                        client_id: 2,
                        client_secret:
                            "tPtDTLslhBi4B4IA1HNzX9XVHlIOqHjVc96AcXOM",
                        scope: "application",
                    }, 
                    {
                        headers: {
                        }
                    })
                    .then((response) => {
                        if (response.data) {
                        	if(response.data.error) {
                        		reject(response.data.message)
                        	}

                            response.data.timestamp = new Date().getTime();
                            store.set('client_auth', response.data);
                            localStorage.setItem("store.state.client_auth", JSON.stringify(response.data));
                            localStorage.setItem("store.state.client_auth_date", JSON.stringify(new Date().toUTCString()));
                            resolve();
                        }
                    })
                    .catch((error) => {
                        alert("Error when trying to authenticate client");
                        Bugsnag.notify(error);
                        reject(error.response.data);
                    });
            });
        },

        oauthRefreshToken() {
            var self = this;

            return new Promise(function(resolve, reject) {
                axios
                    .post("/oauth/token", {
                        grant_type: "refresh_token",
                        refresh_token: store.state.auth.refresh_token,
                        client_id: 2,
                        client_secret:
                            "tPtDTLslhBi4B4IA1HNzX9XVHlIOqHjVc96AcXOM",
                        scope: "application",
                    })
                    .then((response) => {

                        if (response.data) {

                        	if(response.data.error) {
                        		reject(response.data.message)
                        	}

                            response.data.timestamp = new Date().getTime();
                            store.set('auth', response.data);
                            localStorage.setItem("store.state.auth", JSON.stringify(response.data));
                            resolve();
                        }
                    })
                    .catch((error) => {
                        Bugsnag.notify(error);
                        reject(error.response.data);
                    });
            });
        },

        checkTokenUser() {
            var self = this;

            return new Promise(function(resolve, reject) {
                axios
                    .get("/api/whoami", {
                        headers: {
                            Authorization:
                                "Bearer " + store.getUserAuthAccessToken(),
                        },
                    })
                    .then((response) => {
                        if (response.data) {
                        	if(response.data.admin) {
                                store.set('user', response.data);
                                store.set('isLogged', true);
                                store.set('isLoading', false);

                                let pushRoute;
                                if (window.location.pathname == '/admin-panel/login') {
                                    
                                    const redirectToAfterLogin = store.state.redirectToAfterLogin;
                                    if (redirectToAfterLogin) {
                                        pushRoute = redirectToAfterLogin;
                                        store.set('redirectToAfterLogin', null);
                                    } else {
                                        pushRoute = '/admin-panel/dashboard';
                                    }

                                    self.$buefy.toast.open({
                                        message: 'Logado!',
                                        type: 'is-success',
                                    })

                                } else {
                                    pushRoute = window.location.pathname;
                                }

                                self.$router.push({ path: pushRoute }).catch(failure => {
                                    if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
                                        console.log("🚀 ~ file: auth.js:180 ~ self.$router.push ~ failure:", failure)
                                    }
                                })

                                resolve();

                        	} else {
                        		reject("User is not Admin");
                        	}
                        } else {                        	
	                		reject("No data coming from request");
                        }
                    })
                    .catch((error) => {
                        Bugsnag.notify(error);
                        console.log(error);
                        reject(error);
                    });
            });
        },

        refreshPage() {
            var self = this;
            return new Promise(function(resolve, reject) {
                self.$forceUpdate();
                resolve();
            })
        },

        loginFromToken() {
            var self = this;

            store.set('auth', store.state.auth);

            return new Promise(function(resolve, reject) {
                axios
                    .get("/api/whoami?with=cards,address", {
                        headers: {
                            Authorization:
                                "Bearer " + store.getUserAuthAccessToken(),
                        },
                    })
                    .then((response) => {
                        
                        if (response.data) {
                            store.set('user', response.data);
                            store.set('isLogged', true);
                            resolve();
                        } else {                            
                            reject("No data coming from request");
                        }
                    })
                    .catch((error) => {
                        if(error.response.status === 401) {
                            resolve();
                        } else {
                            Bugsnag.notify(error);
                            console.log(error);
                            reject(error);
                        }
                    });
            });


        },

        /*
        checkIfIsAdmin() {
            var self = this;

            return new Promise(function(resolve, reject) {
                if(store.state.user.admin) {
                    store.state.isLogged = true;
                    resolve();
                } else {
                    store.state.user = null;
                    reject("User is not Admin");
                }
            });
        }
        */
    },

    
}