require("./bootstrap");
require("./classes");
require("./directives");

import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import routes from "./routes-testimony";

import Buefy from "buefy";
import "buefy/dist/buefy.css";

Vue.use(Buefy);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

window.Bugsnag = Bugsnag.start({
  apiKey: '0eede0daeb912f7e0513e87c809e6cf4',
  plugins: [new BugsnagPluginVue()]
})

Bugsnag.getPlugin('vue')
  .installVueErrorHandler(Vue)

if(location.hostname.match('devorama')){
	window.devorama = true;
	Vue.config.devtools = true;
}

import messages from "./messages";
import mixin_general from "./mixins/general";
import mixin_auth from "./mixins/auth";

Vue.component("navbar", () => import("./components/common/navbar.vue"));
Vue.component("login", () => import("./components/common/login.vue"));

if(!window.locale || (window.locale !== 'pt' &&  window.locale !== 'en')) {
	window.locale = "pt";
}

// Create VueI18n instance with options
window.i18n = new VueI18n({
	locale: window.locale, // set locale
	fallbackLocale: window.locale,
	messages, // set locale messagess
	silentTranslationWarn: true,
});

window.EventBus = new Vue();

window.store = {
	debug: true,
	state: {
		isLoading: true,
		isLogged: true,
		cms: [],
		countries: [],
		auth: {},
		client_auth: {},
		user: {},
	},
	callbackAfterSet: null,
	set(key, value) {
		this.state[key] = value;
		if (this.callbackAfterSet) this.callbackAfterSet(this.state);
	},
};

let app = new Vue({
	el: "#app",

	mixins: [mixin_general, mixin_auth],

	router: new VueRouter(routes),
	i18n,

	data: {
		privateState: {},
		store: store.state,
	},

	created() {
		console.log('created');
		queue.push(this, [
			'getClientToken',
			// 'emitEvent'
		]);
	},

	methods: {
		// emitEvent() {
        //     return new Promise(function(resolve, reject) {
		// 		// first you register the $on callbacks and then you call $emit
		// 		EventBus.$on('page-mounted', function() {
		// 			EventBus.$emit('vaaaai');
		// 			console.log('asdasd');
		// 		});
		// 		resolve();
        //     });
		// },

		remountComponent(store) {
			this.store = store;
		},

		access_token() {
			return this.store?.auth?.access_token ? this.store?.auth?.access_token : this.store?.client_auth?.access_token;
		}
	}
});

window.store.callbackAfterSet = (store) => {
	app.remountComponent(store);
}