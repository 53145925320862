let Index = () => import('./components/web-testimony/Index');

export default {
	routes: [
		{
			path: '/',
			component: Index
		},
		{
			path: '/testimony',
			component: Index
		},
	],
}