export class Errors {
    constructor() {
        this.errors = {};
    }

    has(field) {
        return this.errors.hasOwnProperty(field);
    }

    any() {
        return Object.keys(this.errors).length > 0;
    }

    get(field) {
        if (this.errors[field]) {
            return this.errors[field][0];
        }
    }

    record(errors) {
        this.errors = errors;
    }

    clear(field) {
        if (field) {
            delete this.errors[field];
        }
        this.errors = {};
    }
}

export class Form {
    constructor(data) {
        //deep copy data to reset form
        this.originalData = JSON.parse(JSON.stringify(data));
        this.data = data;
        this.errors = new Errors();
        if (store.getUserAuthAccessToken) {
            this.auth_token = store.getUserAuthAccessToken() ? store.getUserAuthAccessToken() : store.state.client_auth.access_token;
        } else {
            this.auth_token = store.state.client_auth.access_token;
        }
    }

    reset() {
        this.data = JSON.parse(JSON.stringify(this.originalData));

        //this.errors.clear();
    }

    fill(object) {
        object = JSON.parse(JSON.stringify(object));
        for (let prop in this.data) {
            if (object.hasOwnProperty(prop)) this.data[prop] = object[prop];
        }
    }

    post(url, newOptions = null) {
        // cria novo
        const defaultOptions = {
            headers: {
                Authorization:
                    "Bearer " + this.auth_token,
            },
        };

        let options;
        if (newOptions == null) {
            options = defaultOptions;
        } else {
            options = newOptions;
        }

        return new Promise((resolve, reject) => {
            axios
                .post(url, this.data, options)
                .then((response) => {
                    this.onSuccess(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    this.onFail(error.response.data);
                    reject(error.response.data);
                });
        });
    }

    put(url, newOptions = null) {
        // atualiza inteiramente
        const defaultOptions = {
            headers: {
                Authorization:
                    "Bearer " + this.auth_token,
            },
        };

        let options;
        if (newOptions == null) {
            options = defaultOptions;
        } else {
            options = newOptions;
        }

        return new Promise((resolve, reject) => {
            axios
                .put(url, this.data, options)
                .then((response) => {
                    this.onSuccess(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    this.onFail(error.response.data);
                    reject(error.response.data);
                });
        });
    }

    patch(url, newOptions = null) {
        // atualiza parcialmente
        const defaultOptions = {
            headers: {
                Authorization:
                    "Bearer " + this.auth_token,
            },
        };

        let options;
        if (newOptions == null) {
            options = defaultOptions;
        } else {
            options = newOptions;
        }

        return new Promise((resolve, reject) => {
            axios
                .patch(url, this.data, options)
                .then((response) => {
                    this.onSuccess(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    this.onFail(error.response.data);
                    reject(error.response.data);
                });
        });
    }

    onSuccess(response) {
        this.errors.clear();
    }

    onFail(error) {
        this.errors.record(error.errors);
    }
}

export class RequestQueue {
    constructor() {
        this.q = async.queue(function(task, callback) {
            //chama a função em task.func, no contexto task.context com os parametros task.args
            task.context[task.func](...task.args)
                .then(function(response) {
                    callback(response);
                })
                .catch((error) => {
                    console.error("Function on queue failed!", error);
                    if (error.message) {
                        //alert(error.message);
                        console.error(error.message);
                    }
                    callback("error");
                }).finally(() => {
                    store.set('isLoading', false);
                });
        }, 1);

        this.q.drain(function() {
            console.log('Queue drained.')
            //todos os items foram processados
            store.set('isLoading', false);
        });
    }

    push(context, tasks, args = []) {
        console.log('Queue pushing', tasks);
        //mostra o loading
        store.set('isLoading', true);

        //só para funcionar para arrays ou não arrays
        if (!Array.isArray(tasks)) {
            tasks = [tasks];
        }

        //salva as tasks como objetos contendo a função e o contexto (necessário para executar a função)
        let taskObjects = [];
        tasks.forEach(function(task) {
            taskObjects.push({ context: context, func: task, args: args });
        });

        var self = this;
        //coloca as tasks na queue
        this.q.push(taskObjects, function(res){
            if(res==='error') {
                self.q.kill();
            }
        });
    }
}
